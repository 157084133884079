<template>
  <v-form ref="form" lazy-validation @submit.prevent="onSubmit" class="app-form mt-4">
    <h4
      class="app-stepper-title"
    >Criar pedido de Renovação de Cadastro de Empresa para a empresa com CNPJ abaixo?</h4>

    <v-row no-gutters>
      <v-col md="12" sm="12" xs="12">
        <v-text-field
          label="CNPJ*"
          placeholder="Digite o CNPJ da empresa que deseja fazer"
          prepend-inner-icon="apartment"
          outlined
          clearable
          color="success"
          v-model="empresaForm.cnpj"
          :rules="[rules.required]"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" depressed type="submit">Continuar</v-btn>
    </v-card-actions>
    <section class="loading" v-if="form.isLoading">
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GlobalStore from "@/store/modules/global";

export default {
  name: "RenovacaoCadastroEmpresaForm",
  data: () => ({
    empresaForm: {
      cnpj: null
    },
    rules: {
      required: value => !!value || "Esse campo deve ser preenchido",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail inválido.";
      }
    }
  }),
  methods: {
    onSubmit() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.createRenovacaoCadastroEmpresa(this.empresaForm);
      }
    },
    clearEmpresaForm() {
      this.empresaForm = {
        cnpj: null
      };
    },
    ...mapActions("renovacaoCadastroEmpresa", {
      createRenovacaoCadastroEmpresa: "createRenovacaoCadastroEmpresa"
    })
  },
  computed: {
    ...mapState("renovacaoCadastroEmpresa", ["form"]),
    user: () => GlobalStore.user || {}
  },
  watch: {
    // verifica se muda o step para limpar os campos do form
    "form.currentStep": function(newValue, oldValue) {
      if (newValue) {
        this.clearEmpresaForm();
      }
    }
  },
  mounted() {
    this.empresaForm.cnpj = this.user.cnpj;
  }
};
</script>
