<template>
  <v-layout align-center justify-center>
    <v-flex xs11 sm12 md8 lg8>
      <app-snack-bar></app-snack-bar>
      <v-card class="app-card">
        <v-card-title class="app-card-title">Renovação de Cadastro de Empresa</v-card-title>
        <v-card-text class="app-card-content">
          <v-stepper outlined alt-labels class="app-stepper" v-model="form.currentStep">
            <v-stepper-header class="app-stepper-header">
              <v-stepper-step
                step="1"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 1"
              >Empresa</v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                step="2"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 2"
                :editable="form.currentStep === 3"
              >Documentos</v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                step="3"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 3"
              >Pagamento</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <EmpresaForm />
              </v-stepper-content>
              <v-stepper-content step="2">
                <DocumentosForm />
              </v-stepper-content>
              <v-stepper-content step="3">
                <PagamentoForm />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EmpresaForm from "./EmpresaForm";
import DocumentosForm from "./DocumentosForm";
import PagamentoForm from "./PagamentoForm";
import GlobalStore from "@/store/modules/global";
import AppSnackBar from "@/components/AppSnackBar";

export default {
  name: "RenovacaoCadastroEmpresa",
  components: {
    EmpresaForm,
    DocumentosForm,
    PagamentoForm,
    AppSnackBar
  },
  methods: {
    ...mapActions("renovacaoCadastroEmpresa", {
      clearForm: "clearForm"
    })
  },
  computed: {
    ...mapState("renovacaoCadastroEmpresa", ["form"])
  },
  destroyed() {
    this.clearForm();
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 275px;
  margin: 0px auto 20px auto;
  display: table;
}
</style>
